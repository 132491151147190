import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TooltipWrapper } from '../TooltipWrapper';
import { Tooltip, Zoom } from '@mui/material';
import { RiInformationFill } from 'react-icons/ri';

export function FormInput(props) {
  const [focused, setFocused] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const {
    errorMessage,
    onChange,
    beforeLabel,
    afterLabel,
    flyingLabel,
    divClass,
    counter,
    labelWrap,
    textarea,
    className,
    simple,
    bone,
    password,
    toggle,
    containerRef,
    ...inputProps
  } = props;

  //   Legend: (Forminput attributes)
  //   -simple = simple input with Label and Errormessage
  //   -password = password input with hide/show, wrapping div, Label and Errormessage
  //   -divClass = Div wrapping input and afterLabel/beforelabel/flyinglabel
  //   -labelWarp = beforeLabel and a label Wrapping input, afterLbale and Errormessage
  //   -textarea  = set true, to use textareaautoresize component from their importet library.
  //   +beforeLabel  = provide Object with css and a string key and inside ThemeConsumer, a string
  //   +afterLable = just a string
  //   +counter = counter for string length. css, value, minLength and maxLength attributes
  //   +errormessage = errormessage dispalying if input is invalid due to pattern/required. peer class for input required

  useEffect(() => {
    setFocused(false);
  }, [props.value]);

  const handleFocus = () => {
    setFocused(true);
  };
  return (
    <>
      {bone && (
        <>
          <Label id={props.id} beforeLabel={beforeLabel} />
          {counter ? (
            <label
              htmlFor={props.id}
              className={`cursor-text ${counter.css} ${
                counter.min > counter.val || counter.val > counter.max ? 'text-red-400' : 'text-gray-400'
              }`}>
              {counter.val + '/' + counter.max}
            </label>
          ) : null}
          <input
            {...inputProps}
            className={className}
            onChange={onChange}
            onBlur={handleFocus}
            data-focused={focused.toString()}
          />
          <ErrorMessage
            type={props.type}
            disabled={props.disabled}
            focused={focused}
            errorMessage={errorMessage}></ErrorMessage>
          <Label id={props.id} afterLabel={afterLabel} />
        </>
      )}
      {simple && (
        <>
          <Label id={props.id} beforeLabel={beforeLabel} />
          {counter ? (
            <label
              htmlFor={props.id}
              className={`cursor-text ${counter.css} ${
                counter.min > counter.val || counter.val > counter.max ? 'text-red-400' : 'text-gray-400'
              }`}>
              {counter.val + '/' + counter.max}
            </label>
          ) : null}
          <div className="flex flex-wrap">
            {/* <div
              className={`${
                props.type === 'number' ? 'w-5' : 'w-10'
              } pointer-events-none z-10 flex items-center justify-center pl-1 text-center`}>
              <i className="mdi mdi-email-outline text-lg text-gray-400"></i>
            </div> */}
            <input
              {...inputProps}
              className={className}
              onChange={onChange}
              onBlur={handleFocus}
              data-focused={focused.toString()}
            />
            <div className="h-0 w-full"></div>
            <ErrorMessage
              type={props.type}
              disabled={props.disabled}
              focused={focused}
              errorMessage={errorMessage}></ErrorMessage>
          </div>
          <Label id={props.id} afterLabel={afterLabel} />
        </>
      )}
      {password && (
        <>
          <Label
            id={props.id}
            password={true}
            matchpassword={props.name == 'matchpassword'}
            signin={!!props.signin}
            beforeLabel={beforeLabel}
            containerRef={containerRef}
          />
          <div className={`relative ${password}`}>
            <div className="flex flex-wrap">
              <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                <i className="mdi mdi-email-outline text-lg text-gray-400"></i>
              </div>
              <input
                {...inputProps}
                className={className}
                type={isHide ? 'password' : 'text'}
                onChange={onChange}
                onBlur={handleFocus}
                data-focused={focused.toString()}
              />
              <button
                type="button"
                className="hover:text-main absolute right-2 top-2 block h-7 w-7 text-center text-xl text-gray-400 transition-colors focus:outline-none"
                onClick={() => setIsHide((prev) => !prev)}>
                {isHide ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </button>
              <div className="h-0 w-full"></div>
              <ErrorMessage
                type={'password'}
                disabled={props.disabled}
                focused={focused}
                errorMessage={errorMessage}></ErrorMessage>
            </div>

            {flyingLabel ? (
              <label
                htmlFor={props.id}
                className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 cursor-text text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-blue-600 ">
                {flyingLabel}
              </label>
            ) : null}
          </div>
        </>
      )}
      {divClass && (
        <>
          <Label id={props.id} beforeLabel={beforeLabel} />
          <div className={divClass}>
            {counter ? (
              <label
                htmlFor={props.id}
                className={`cursor-text ${counter.css} ${
                  counter.min > counter.val || counter.val > counter.max ? 'text-red-400' : 'text-gray-400'
                }`}>
                {counter.val + '/' + counter.max}
              </label>
            ) : null}
            <div className="flex flex-wrap">
              <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                <i className="mdi mdi-email-outline text-lg text-gray-400"></i>
              </div>
              <input
                {...inputProps}
                className={`${className}`}
                onChange={onChange}
                onBlur={handleFocus}
                data-focused={focused.toString()}
              />
              <div className="h-0 w-full"></div>
              <ErrorMessage
                type={props.type}
                disabled={props.disabled}
                focused={focused}
                errorMessage={errorMessage}></ErrorMessage>
              {flyingLabel ? (
                <label
                  htmlFor={props.id}
                  className="absolute left-2.5 top-5 z-10 origin-[0] -translate-y-4 scale-75 cursor-text text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-blue-600    ">
                  {flyingLabel}
                </label>
              ) : null}
            </div>

            <Label id={props.id} afterLabel={afterLabel} />
          </div>
        </>
      )}
      {labelWrap && (
        <>
          <Label id={props.id} beforeLabel={beforeLabel} />
          <label className={labelWrap.css} htmlFor={props.id}>
            <Label id={props.id} afterLabel={afterLabel} />
            <input
              {...inputProps}
              className={className}
              onChange={onChange}
              onBlur={handleFocus}
              data-focused={focused.toString()}
            />
            {labelWrap.string}

            <ErrorMessage
              type={props.type}
              disabled={props.disabled}
              focused={focused}
              errorMessage={errorMessage}></ErrorMessage>
          </label>
        </>
      )}
      {toggle && (
        <label className={`relative mr-5 inline-flex h-fit items-center ${className}`}>
          <input type="checkbox" className="peer sr-only" readOnly checked={inputProps.checked} />
          <div
            onClick={onChange}
            className="peer h-6 w-11 cursor-pointer rounded-full bg-gray-200  after:absolute  after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#2688EB] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-[#2688EB]"></div>
          <span className="ml-2 text-sm font-medium text-gray-900">
            {inputProps.checked ? 'Geöffnet' : 'Geschlossen'}
          </span>
        </label>
      )}
      {textarea && (
        <>
          <Label id={props.id} beforeLabel={beforeLabel} />
          {counter ? (
            <label
              htmlFor={props.id}
              className={` ${counter.css} ${
                (counter.min > counter.val && counter?.val !== 0)  || counter.val > counter.max ? 'text-red-400' : 'text-gray-400'
              }`}>
              {(counter?.val ?? 0) + '/' + counter.max}
            </label>
          ) : null}
          <div className="flex flex-wrap">
            <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
              <i className="mdi mdi-email-outline text-lg text-gray-400"></i>
            </div>
            <TextareaAutosize
              {...inputProps}
              className={className}
              onChange={onChange}
              onBlur={handleFocus}
              data-focused={focused.toString()}
            />
            <div className="h-0 w-full"></div>
            <ErrorMessage
              type={props.type}
              disabled={props.disabled}
              focused={focused}
              errorMessage={errorMessage}></ErrorMessage>
          </div>
          <Label id={props.id} afterLabel={afterLabel} />
        </>
      )}
    </>
  );
}
function Label(props) {
  return (
    <>
      {props.beforeLabel || props.afterLabel ? (
        <label
          htmlFor={props.id}
          className={
            props.beforeLabel
              ? `block ${props.beforeLabel.css}`
              : props.afterLabel
              ? `mr-2 block ${props.afterLabel.css}`
              : ''
          }>
          {props.password ? (
            <div className="flex">
              {props.beforeLabel ? props.beforeLabel.string : props.afterLabel ? props.afterLabel.string : null}
              {!props.matchpassword && !props.signin && (
                <Tooltip
                  title={<p className="!text-sm normal-case">Dein Passwort muss 8-20 Zeichen lang sein</p>}
                  placement={'top'}
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={9500}
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        // display: { xs: 'none', sm: 'block' },
                        width: '20rem',
                        bgcolor: 'grey.800',
                        '& .MuiTooltip-arrow': {
                          color: 'grey.800',
                        },
                        // zIndex: 1500,
                      },
                    },
                  }}
                  PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                      positionFixed: true,
                      modifiers: {
                        preventoverflow: {
                          enabled: true,
                          boundarieselement: props.containerRef || undefined, // where "window" is the boundary
                        },
                      },
                    },
                  }}>
                  <TooltipWrapper className="ml-1 flex">
                    <RiInformationFill className="h-4 w-4 text-gray-400" />
                  </TooltipWrapper>
                </Tooltip>
              )}
            </div>
          ) : null}
          {props.beforeLabel && !props.password
            ? props.beforeLabel.string
            : props.afterLabel
            ? props.afterLabel.string
            : null}
          {props.beforeLabel?.required ? '*' : null}
          {props.afterLabel?.string2 ? props.afterLabel.string2 : null}
          {props.beforeLabel?.description ? (
            <p className="text-second text-sm font-normal normal-case md:text-base">{props.beforeLabel.description}</p>
          ) : null}
          {props.afterLabel?.description ? (
            <p className="text-second text-sm font-normal normal-case md:text-base">{props.afterLabel.description}</p>
          ) : null}
        </label>
      ) : null}
    </>
  );
}
function ErrorMessage(props) {
  return (
    <>
      {props.errorMessage ? (
        <p
          title={props.errorMessage}
          className={
            props.focused && props.type === 'password'
              ? 'line-clamp-1 block pl-5 text-xs text-red-500 peer-valid:hidden peer-invalid:visible sm:text-xs'
              : props.focused && props.type === 'email'
              ? 'line-clamp-1 block pl-5 text-xs text-red-500 peer-valid:hidden peer-invalid:visible sm:text-xs'
              : props.type === 'number' && !props.disabled && props.focused
              ? 'line-clamp-1 text-xs text-yellow-500 peer-valid:hidden peer-enabled:block sm:text-sm'
              : props.focused
              ? 'line-clamp-1 block pl-5 text-xs text-red-500 peer-valid:hidden peer-invalid:visible sm:text-xs'
              : 'hidden'
          }>
          {props.errorMessage}
        </p>
      ) : null}
    </>
  );
}
